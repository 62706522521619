import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import SearchControlFormLayout from 'components/domain/search-control/components/SearchControlFormLayout/SearchControlFormLayout';

export const SrlContentWrapper = styled.div(
  sx2CssThemeFn({
    display: [null, 'grid'],
    gridTemplateColumns: [null, '250px 1fr'],
    mt: [null, '25px'],
    position: [null, 'relative'],
  })
);

export const SrlMobileOverlayWrapper = styled(WhiteBoxModal)(
  sx2CssThemeFn({
    '.modal-body, .modal-header': {
      bg: 'backgroundGray',
      borderColor: 'borderGray',
      '.modal-title': {
        color: 'secondary',
      },
    },
  })
);

export const SRLResultsWrapper = styled.div(
  sx2CssThemeFn({
    ml: [0, 3],
    flex: 1,
  })
);

export const SrlSearchControlFormWrapper = styled(SearchControlFormLayout)(
  sx2CssThemeFn({
    maxWidth: ['100%', '250px'],
    boxShadow: 'unset',
    '.fields': {
      backgroundColor: 'white',
      pt: [4, 2],
      pb: [4, 2],
      borderTop: ['1px solid', 'none'],
      borderBottom: ['1px solid', 'none'],
      borderColor: 'borderGray',
    },
  })
);

export interface ISrlToolbarButtonProps {
  active?: boolean;
}

export const SrlToolbarButton = styled(Button)<ISrlToolbarButtonProps>(
  ({ theme }) => theme.buttons.btnWithIcon,
  ({ active }) =>
    sx2CssThemeFn({
      marginLeft: 0,
      maxWidth: ['100px', 'none'],
      bg: ['#e6e6e6', 'transparent'],
      background: [null, 'linear-gradient(to bottom,#fff 0,#e7e7e7 100%)'],
      color: ['textGray', active ? 'secondary' : 'textGray'],
      borderLeft: '1px solid #bbb',
      borderRight: '1px solid #bbb',
      borderTop: ['1px solid #bbb', 'none'],
      borderBottom: [
        `1px solid ${active ? 'transparent' : '#bbb'}`,
        '1px solid #bbb',
      ],
      fontSize: ['14px', 0],
      fontFamily: 'Arial',
      boxShadow: '1px 0 2px rgb(203 200 194 / 25%)',
      flexDirection: 'row-reverse',
      height: '30px',
      '&:hover': {
        background: [null, 'linear-gradient(to bottom,#fdfdfd 0,#d0d0d0 100%)'],
      },
      '.icon': {
        flexShrink: 0,
      },
      gap: [1, 2],
      pl: [2, 2],
    })
);

export const FilterButton = styled(Button).attrs({
  icon: {
    name: 'filter',
  },
})(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 'default',
    flexShrink: 0,
    textTransform: 'uppercase',
    '.icon': {
      mr: 2,
    },
  })
);
